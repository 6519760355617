import React from 'react'
import image from '../../assets/img/not-found-background.jpg'
import './notFound.scss'

export default function NotFound(){
  return(
    <section className='not-found-page'>
      <img src={image} alt="404 image" />
      <a className='text-center bg-red-600 btn-cta-video button' href='/'>Volver</a>
    </section>
  ) 
}
