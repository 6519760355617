// Libs
import React, { useEffect, useState } from "react";
import AOS from "aos";
import { Modal } from "antd";

import "./styles.scss";
// Components
import Intro from "./Intro";
import LearningList from "./LearningList";
import AboutMe from "./AboutMe";
import Testimonials from "./Testimonials";
import Footer from "./Footer";
import HomeForm from "./HomeForm";
import Loader from "../../../components/loader";


const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    AOS.init();
    // Reset Scroll
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="home">
      <Loader />
      <Modal
        open={isModalOpen}
        onCancel={closeModal}
        ok
        style={{ top: 20 }}
        maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.850)" }}
        footer={
          <div className="flex justify-center items-center px-10">
            <button className="w-full border-2 border-stone-300 rounded p-3 hover:bg-stone-300 hover:text-white transition-all" onClick={closeModal}>Cancel</button>
          </div>
        }
      >
        <HomeForm />
      </Modal>
      <section className="home-wrapper grid">
        <div className='text-white bg-red-800 p-6 text-center mb-6'>
          <span className="font-medium">ENTRENAMIENTO 100% GRATIS</span>
        </div>
        <Intro showModal={showModal} />
        <LearningList showModal={showModal} />
        <AboutMe />
        <Testimonials showModal={showModal} />
        <Footer showModal={showModal} />
      </section>
    </div>
  );
};

export default Home;
