import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import Newsletter from './pages/newsletter/newsletter';
import NotFound from './pages/notFound/notFound';
import TinderCourseLanding from './pages/tinderCourseLanding/tinderCourseLanding';

// V2
import Home from './pages/v2/Home';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/newsletter" element={<Newsletter />} />
        <Route exact path='/curso' element={<TinderCourseLanding />} />
        <Route exact path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
