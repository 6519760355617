import React from "react";
// Components
import Layout from '../../../components/v2/Layout'

const Footer = ({showModal}) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear()

  return (
    <Layout>
      <footer className="md:px-[13rem]">
        <div
          className="border-2 py-6 rounded-lg border-red-800 flex flex-col justify-center items-center"
          data-aos="zoom-in-up"
        >
          <div className="px-5">
            <span className="text-red-800 uppercase font-semibold text-base">
              Acceso Inmediato
            </span>
          </div>
          <div className="mt-2 text-center px-5 mb-3">
            <span className="font-extrabold text-3xl">
              Entrenamiento Gratuito
            </span>
          </div>
          <ul className="mt-3">
            <li className="text-base mt-2 mb-2">🔥 Introduce tus datos</li>
            <li className="text-base mt-2 mb-2">🔥 Disfruta del entrenamiento</li>
            <li className="text-base mt-2 mb-2">🔥 Descubre los 8 pasos</li>
          </ul>
          <div className="cta flex justify-center items-center mt-6">
            <button className="bg-red-800 p-5 w-full rounded font-bold text-base" onClick={showModal}>
              QUIERO ACCEDER GRATIS
            </button>
          </div>
        </div>

        <ul className='details py-3 mt-20'>
          <li className='text-center'>
            Copyright &copy; {year} PerdidosEnSlavia. Todos los derechos reservados.
            </li>
          <li className='text-center mt-5'>
            Desarrollado por <a href="https://carlosleoncode.com/" target="_blank" rel="noopener noreferrer" className='italic'>CarlosLeonCode</a>
          </li>
        </ul>
      </footer>
    </Layout>
  );
};

export default Footer;
