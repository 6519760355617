import {React} from 'react';
import './footer.scss'

export default function Footer(){

  const currentDate = new Date();
  const year = currentDate.getFullYear()

  return (
    <footer className='flex justify-center bg-red-900'>
      <ul className='details py-3'>
        <li className='text-center'>
          Copyright &copy; {year} PerdidosEnSlavia. Todos los derechos reservados.
          </li>
        <li className='text-center mt-5'>
          By <a href="https://carlosleoncode.com/" target="_blank" rel="noopener noreferrer" className='italic'>CarlosLeonCode</a>
        </li>
      </ul>
    </footer>
  )
}
