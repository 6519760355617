import React from 'react'
// Assets 
import './styles.scss'
import Video from '../../../assets/img/intro-home.gif'
// Components
import Layout from '../../../components/v2/Layout'

const Intro = ({showModal}) => {
  return(
    <Layout>
      <section className='w-full md:gap-8 md:h-[90vh]'>
        <figure className='mt-2 grid place-items-center'>
          <img src={Video} className='rounded md:w-7/12' ></img>
        </figure>
        <div className="grid place-items-center md:place-content-start md:px-[10rem] mt-10" >
          <p className='font-extrabold text-xl uppercase'>
            Te ayudo a convertirte en el hombre que atrae y conserva a 
            la mujer que sueña. Sin esfuerzo y en 90 días. Si no lo logras
            te devuelvo el dinero y te ayudo gratis 1 a 1 hasta que lo consigas.
          </p>
          <p className='font-light text-md'>
            En este <strong className='font-bold'>entrenamiento de 15 minutos</strong> te guiaré por 8 
            sencillos pasos para aumentar tu estatus, valor social, masculinidad y atractivo
            como hombre.
          </p>
        </div>
        <div className="cta flex justify-center items-center mt-6 md:col-span-2">
          <button className='bg-red-800 p-5 w-full md:w-10/12 rounded' data-aos="fade-up" onClick={showModal}>
            DESBLOQUEAR ENTRENAMIENTO
          </button>
        </div>
      </section>
    </Layout>
  )
}

export default Intro
