import React from "react";
import { Button, Form, Input } from "antd";
import Alerts from "../../utils/alerts";

export default function NewsletterForm() {
  const { Item } = Form;
  const onSend = async (event) => {
    const { email } = event;
    const loader = document.querySelector(".loader-wrapper");
    loader.classList.add('show')
  
    const emailData = {
      service_id: "service_ftz9b33",
      template_id: "template_sfup66t",
      user_id: "wKHlnih6uRwvs6Ng3",
      template_params: { email },
    };
    // Send email
    const response = await fetch(
      "https://api.emailjs.com/api/v1.0/email/send",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      }
    );
    // hide loader
    loader.classList.remove("show");
    // Response
    if (response["status"] == 200) {
      Alerts.success("Te has suscrito al Newsletter exitosamente!")
    } else {
      Alerts.error()
    }
  };

  return (
    <Form layout="horizontal" onFinish={onSend}>
      <div className="grid grid-cols-1 md:grid-cols-4">
        <Item
          className="md:col-span-3"
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Por favor ingresa tu email.",
              type: "email",
            },
          ]}
        >
          <Input placeholder="Ingresa tu correo..." />
        </Item>
        <Item className="md:col-span-1">
          <Button className="submitBtn" htmlType="submit">
            ¡Suscribirme!
          </Button>
        </Item>
      </div>
    </Form>
  );
}
