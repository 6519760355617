import React from "react";
import Photo from "../../../assets/img/isra.jpg";

// Components
import Layout from '../../../components/v2/Layout'

const AboutMe = () => {
  return (
    <Layout>
      <section className="mt-12 flex flex-col md:flex-row-reverse md:gap-8 md:mb-[12rem]">
        <figure className="mt-2">
          <img
            src={Photo}
            alt="photo"
            className="rounded md:w-[200rem]"
            data-aos="zoom-in"
          />
        </figure>
        <div>
          <h3 className="text-center font-bold text-2xl mt-8 mb-8">
            ¿Por qué confiar en mi?
          </h3>
          <p className="text-justify text-xl mt-14" data-aos="zoom-in">
            <span>
              Soy un experto en relaciones con <strong>+250k</strong> seguidores en
              redes sociales y he ayudado a miles de hombres a atraer mujeres.
            </span>
            <span>
              He viajado y vivido por más de <strong>20 paises</strong> y en todos
              ellos tengo éxito con mi sistema, además he superado relaciones largas
              y he creado fuentes de ingreso con diferentes negocios que también te enseñaré.
            </span>
            <span>
              He creado una estrategia única con la que cada vez más hombres
              disfrutan de esta abundancia en sus vidas.
            </span>
          </p>
          <div className="cards mt-10 grid grid-cols-3 gap-1">
            <div className="grid grid-rows-2 justify-center text-center p-2" data-aos="flip-up">
              <span className="font-extrabold text-2xl text-red-800">+150k</span>
              <span className="font-extralight mt-1">Subs Youtube</span>
            </div>

            <div className="grid grid-rows-2 justify-center text-center p-2" data-aos="flip-up">
              <span className="font-extrabold text-2xl text-red-800">+60M</span>
              <span className="font-extralight mt-1">Views</span>
            </div>

            <div className="grid grid-rows-2 justify-center text-center p-2" data-aos="flip-up">
              <span className="font-extrabold text-2xl text-red-800">+1000</span>
              <span className="font-extralight">Hombres guiados</span>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutMe;
