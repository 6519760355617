import { formatPhoneNumberIntl } from "react-phone-number-input";
import Alerts from "../utils/alerts";

const TEMPLATES = {
  base: "template_pb394ur",
  potential: "template_o1qepoj",
  middle: "template_fhzrncv",
  free_training: "template_kac77c9",
};

const useSendEmails = () => {
  const sendEmail = async (event, phoneIndicator, status, template_id) => {
    // build email data
    const emailData = {
      service_id: "service_ftz9b33",
      template_id: template_id,
      user_id: "wKHlnih6uRwvs6Ng3",
      template_params: {
        country: event["country"],
        countryIndicator: phoneIndicator,
        email: event["email"],
        firstQuestion: event["firstQuestion"],
        name: event["name"],
        phoneNumber: event.phoneNumber,
        secondQuestion: event["secondQuestion"],
        status: status,
        age: event["age"],
      },
    };
    // Send email
    const response = await fetch(
      "https://api.emailjs.com/api/v1.0/email/send",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      }
    );
    return response;
  };

  const sendFreeTraining = async (clientEmail, clientName) => {
    const emailData = {
      service_id: "service_ftz9b33",
      template_id: TEMPLATES.free_training,
      user_id: "wKHlnih6uRwvs6Ng3",
      template_params: {
        client_email: clientEmail,
        name: clientName,
      },
    };
    // Send email
    const response = await fetch(
      "https://api.emailjs.com/api/v1.0/email/send",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      }
    );
    if (response.status !== 200) {
      console.error("Error sending free training!");
    }
    return response;
  };

  const onSend = async (event) => {
    // Phone number format
    const phoneSplitted = formatPhoneNumberIntl(event.phoneNumber).split(" ");
    const phoneIndicator = phoneSplitted.shift();
    const loader = document.querySelector(".loader-wrapper");
    console.log(loader)

    // if it meets the condition, it'll be a Potencial client.
    let status = undefined;
    if (event["secondQuestion"] === "Si") {
      status = "POTENCIAL";
    } else if (
      event["secondQuestion"] ===
      "No los tengo pero puedo conseguirlos en unos días..."
    ) {
      status = "MEDIO";
    } else {
      status = "NO POTENCIAL";
    }

    // Show loader
    loader.classList.add("show");
    // Send email and show booking button
    const response = await sendEmail(
      event,
      phoneIndicator,
      status,
      TEMPLATES.base
    );
    // hide loader
    loader.classList.remove("show");
    if (response.status === 200) {
      sendFreeTraining(event["email"], event["name"]);
      status === "POTENCIAL" && Alerts.successPotential();
      status === "MEDIO" && Alerts.successMiddle();
      status === "NO POTENCIAL" && Alerts.successMiddle();
    } else {
      Alerts.error();
    }
  };
  return {
    onSend,
  };
};

export default useSendEmails;
