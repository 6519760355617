import Swal from 'sweetalert2'

// Sweet alert configuration
const Toast = Swal.mixin({
  toast: false,
  position: 'center',
  showConfirmButton: true,
  timerProgressBar: false,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

export default Toast
