import React, { useEffect } from "react";

// components
import CardList from "../../../components/cardList";
import Layout from '../../../components/v2/Layout'

const LearningList = ({showModal}) => {
  const callback = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      entry.target.classList.remove("bg-white/10");
      entry.target.classList.add("bg-red-800");
    } else {
      entry.target.classList.add("bg-white/10");
      entry.target.classList.remove("bg-red-800");
    }
  };

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    const nodes = document.querySelectorAll(".card-list-home");
    if (nodes) {
      nodes.forEach((node) => observer.observe(node));
    }
  });

  return (
    <Layout>
      <section className="learning-section px-5 text-white md:mb-[12rem]">
        <h3 className="text-center font-bold text-2xl mt-8 mb-8">
         Esto es lo que aprenderás
        </h3>
        <ul className="mt-12 flex flex-col md:flex-row md:gap-6">
          <li>
            <CardList
              title="Clave 1:"
              text="Cómo convertirte en un hombre de éxito y con estatus teniendo
              un estilo de vida atractivo."
            />
          </li>
          <li>
            <CardList
              title="Clave 2:"
              text={`El método de "los embudos" para aumentar tu volumen de mujeres y
              aprovechar las oportunidades que ahora pierdes.`}
            />
          </li>
          <li>
            <CardList
              title="Clave 3:"
              text={`La gran ventaja es que tendrás un plan a seguir, una guia rápida y
              fácil al éxito con ellas.`}
            />
          </li>
        </ul>
        <div className="cta flex justify-center items-center mt-14">
          <button className="bg-red-800 p-5 w-full md:w-10/12 rounded" data-aos="zoom-in" onClick={showModal}>
            VER ENTRENAMIENTO
          </button>
        </div>
      </section>
    </Layout>
  );
};

export default LearningList;
