import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, InputNumber } from 'antd';
import PhoneInput from 'react-phone-number-input'
import useSendEmails from '../../../hooks/useSendEmails';

export default function HomeForm(){
  const { Item } = Form
  const [phoneVal, setPhoneVal] = useState(0)
  const { onSend } = useSendEmails()

  // options for question
  const secondQuestionOptions = [
    { value: 'Si', label: 'Si los tengo' },
    { value: 'No', label: 'No los tengo' },
    { value: 'No los tengo pero puedo conseguirlos en unos días...', label: 'No los tengo pero puedo conseguirlos en unos días...' }
  ]

  useEffect(() => {
    const loader = document.querySelector('.loader-wrapper')
    loader.classList.remove('show')
  })

  const updatePhone = (phone) => setPhoneVal(phone)

  return (
    <section>
      <div className='text-center mb-10'>
        <h1>Proporciona la siguiente información</h1>
      </div>
      <div data-aos="fade-bottom" className='w-full px-10'>
        <Form
          layout='vertical'
          onFinish={onSend}
        >
          <Item 
            label="Nombre"
            name="name"
            rules={[{required: true, message: "Por favor ingresa tu nombre."}]}
          >
            <Input placeholder="Ingresa tu nombre..." className='p-2'/>
          </Item>

          <Item 
            label="Edad"
            name="age"
            rules={[{required: true, message: "Por favor ingresa su edad."}]}
          >
            <InputNumber placeholder="Ingresa tu edad..." className='w-full p-1'/>
          </Item>

          <Item
            label="País"
            name="country"
            rules={[{required: true, message: "Por favor ingresa el pais donde vives."}]}
          >
            <Input placeholder="Ingresa el pais donde vives..." min={1} max={99} className='p-2'/>
          </Item>

          <Item
            label="Teléfono"
            name="phoneNumber"
            rules={[{required: true, message: "Por favor ingresa tu número de teléfono."}]}
          >
            <PhoneInput
              international
              placeholder="Ingresa tu número telefónico..."
              value={phoneVal}
              onChange={updatePhone}
              className='border-[1px] rounded p-2 border-[#d9d9d9]'
            />
          </Item>

          <Item
            label="Email"
            name="email"
            rules={[{required: true, message: "Por favor ingresa tu email.", type: "email"}]}
          >
            <Input  placeholder="Ingresa tu correo..." className='p-2'/>
          </Item>

          <Item
            label="¿Tienes al menos 500€ para invertir en este cambio radical en tu vida...?"
            name="secondQuestion"
            rules={[{required: true, message: "Por favor responde esta pregunta."}]}
          >
            <Select
              placeholder="Selecciona una opción"
              allowClear
              options={secondQuestionOptions}
            />
          </Item>

          <Item>
            <Button className="bg-red-800 w-full text-white h-[3.5rem] hover: border-0 hover:text-white" htmlType="submit">
              ¡Estoy Listo!
            </Button>
          </Item>
        </Form>
      </div>
    </section>
  )
}
