import React from "react";
// Components
import Layout from '../../../components/v2/Layout'

const Testimonials = () => {
  return(
    <Layout>
      <section className="mt-8 md:mb-[6rem]" data-aos="zoom-in">
        <h3 className="text-center font-bold text-2xl mt-8 mb-8">Casos de Éxito</h3>
        <figure>
          <iframe
            className="w-full aspect-video rounded"
            src="https://www.youtube.com/embed/Br08R_QBS6E?si=vl1Gph096j73VmIj"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </figure>
      </section>
    </Layout>
  )
}

export default Testimonials
