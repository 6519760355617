import Toast from "./toast"

const POTENCIALWSPGROUP = 'https://chat.whatsapp.com/IwzTdZa7vPx67566RoOJ7r'
const LOWMIDDLEWSPGROUP = 'https://chat.whatsapp.com/LiTcCtgTTnw3whjdSDxFQj'

const Alerts = {
  successPotential: () => {
    Toast.fire({
      icon: 'success',
      title: 'Envio exitoso',
      confirmButtonColor: '#ccc',
      html: `
      <div class="flex justify-center">
        <iframe class="mb-3 rounded-lg" width="350" height="600" src="https://www.youtube.com/embed/GKpsk-1Yg3o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <a target="_blank" href="${POTENCIALWSPGROUP}">
        <Button class="bg-red-600 p-3 rounded text-white">Ingresar al grupo de WhatsApp</Button>
      </a>`,
      showConfirmButton: false,
      showCloseButton: true
    })
  },
  successMiddle: () => {
    Toast.fire({
      icon: 'success',
      title: 'Envio exitoso',
      html: `
      <div class="flex justify-center">
        <iframe class="mb-3 rounded-lg" width="350" height="600" src="https://www.youtube.com/embed/G9uJxmMxKrc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <a target="_blank" href="${LOWMIDDLEWSPGROUP}">
        <Button class="bg-red-600 p-3 rounded text-white">Ingresar al grupo de WhatsApp</Button>
      </a>`,
      showConfirmButton: false,
      showCloseButton: true
    })
  },
  successLow: () => {
    Toast.fire({
      icon: 'success',
      title: 'Envio exitoso',
      text: 'Serás contactado en los próximos días',
      confirmButtonColor: '#ccc',
      html: `
      <div class="flex justify-center">
        <iframe class="mb-3 rounded-lg" width="350" height="600" src="https://www.youtube.com/embed/G9uJxmMxKrc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <a target="_blank" href="https://calendly.com/perdidosenslavia/30min">
        <Button class="bg-red-600 p-3 rounded text-white">Ingresar al grupo de WhatsApp</Button>
      </a>`,
      showConfirmButton: false
    })
  },
  error: () => {
    Toast.fire({
      icon: 'error',
      title: 'Ooops!',
      text: 'Ha ocurrido un error, intentalo de nuevo!',
      confirmButtonColor: '#000'
    })
  },
  success: (txtMessage) => {
    Toast.fire({
      icon: "success",
      title: "Envio exitoso",
      text: txtMessage,
      confirmButtonColor: "#000",
    });
  }
}

export default Alerts
