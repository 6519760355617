import React, { useEffect } from 'react';
import Loader from '../../components/loader' 
import './newsletter.scss'
import image from '../../assets/img/newsletter-img.jpeg'
import logo from './../../assets/img/logo.svg'
import NewsletterForm from '../../components/newsletter/newsletterForm';
import AOS from 'aos';
import Footer from '../../components/footer/footer';

export default function Newsletter(){

  useEffect(() => {
    AOS.init();
  })

  return (
    <div>
      <div className='newsletter-wrapper grid grid-cols-2 md:grid-cols-3 grid-rows-1 h-screen'>
        <img src={image} alt="" className='newsletter-img-mobile md:hidden' data-aos="flip-left"/>
        <div className="col-span-2 flex justify-center items-center px-3">
          <div className="grid left-section">
            <div className="logo" data-aos='fade-right'>
              <img src={logo} alt="perdidis en slavia logo"/>
            </div>
            <div className="main-phrase" data-aos='fade-right'>
            </div>
            <div data-aos='fade-left'>
              <p className="main-phrase">Todo hombre de valor es HACKER por naturaleza</p>
              <br />
              <p className='second-phrase'>Recibe 3 <strong>HACKS</strong> todos los <strong>JUEVES</strong>. Estos <strong>HACKS</strong> o TRUCOS te ayudaran a: Tener mas abundancia, sobresalir por encima de la competencia y te van a hacer la vida más fácil como hombre.</p>
            </div>
            <div data-aos='fade-up'>
              <NewsletterForm />
            </div>
          </div>
        </div>
        <div className="col-span-1 flex items-center img-wrapper hidden md:flex">
          <img src={image} alt="" className='newsletter-img' data-aos="flip-left"/>
        </div>
      </div>
      <Footer />
      <Loader />
    </div>
  )
}
