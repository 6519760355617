import React from "react";

const CardList = ({title, text}) => {
  return(
    <div className="p-8 rounded mb-6 mt-6 card-list-home md:bg-white/10">
      <span className="font-extrabold text-medium">{title}</span>
      <p className="font-light mt-3">{text}</p>
    </div>
  )
}

export default CardList
