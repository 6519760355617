import React, { useEffect } from 'react'
import Lottie from "lottie-react";
import './styles.scss'
import AOS from 'aos';
import DownButtonAnimation from "../../assets/lottie/DownButtonAnimation.json";
import Footer from '../../components/footer/footer';
import logo from "../../assets/img/logo.svg";

export default function TinderCourseLanding(){
  useEffect(() => {
    AOS.init();
  })

  return(
    <div className='root-landing'>
      {/* Video */}
      <section className='flex justify-center align-center grid grid-cols-12 bg-black py-8'>
        <div className="col-start-3 col-end-11 flex items-center justify-center">
          <div className="icon-wrapper z-10">
            <img
              className="lg:mx-0 mb-4 w-[60px] ml-2 mt-4"
              src={logo}
              alt="perdidos en slavia logo"
            />
          </div>
        </div>
        <div className="col-start-3 col-end-11 flex items-center justify-center">
          <h3 className='text-white mt-8 text-xl text-center'>
          Conviértete en un MASTER en TINDER
          </h3> 
        </div>
      </section>
      <section className='grid grid-cols-12 intro-section'>
        <div className="col-start-3 col-end-11 wrapper" data-aos="fade-up">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/5Uv6NTDm63s?autoplay=1" title="Curso" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          <div className="absolute bottom-[3rem] w-[12rem]">
            <Lottie animationData={DownButtonAnimation} loop={true} />
          </div>
        </div>
      </section>
      <div className="flex items-center justify-center mt-10">
        <a href="https://pay.hotmart.com/K79085615E" target="_blank" className='button-cta' rel="noreferrer">
          <button>Conocer Curso</button>
        </a>
      </div>
      {/* Text */}
      <section className='grid grid-cols-12 p-6 caption-section'>
        <div className="col-start-3 col-end-11 wrapper">
          <p className='caption' data-aos="fade-left">Entiende cómo funciona el Algoritmo y saca provecho de ello</p>
        </div>
      </section>
      <div className="divider">
        <hr />
      </div>
      {/* Card details */}
      <section className='grid grid-cols-12 p-12 details-section'>
        <div className="col-start-3 col-end-11 mt-10 wrapper">
          <div className="card" data-aos="flip-right">
            <div className="card-title">
              <h3>¿Qué vas a aprender?</h3>
            </div>
            <div className="card-body">
              <ol className='list-decimal'>
                <li className='p-5'>Vamos a adaptar tu cerebro a utilizar tinder siendo hombre. Ya que nosotros los hombres no podemos conseguir 300 likes en una hora como puede hacer las mujeres. Y ademas utilizaras nuestro sistema de embudos revolucionario.</li>
                <li className='p-5'>Vamos a enseñarte a preparar un perfil TOP, con fotos y Loops de calidad. Y te mostraremos como sacarle partido a TODAS las opciones de la app.</li>
                <li className='p-5'>Nunca antes te han enseñado a textear, escalar y cualificar como lo haras en este curso.</li>
                <li className='p-5'>Aprenderas a realizar una logística efectiva y estrategica de las citas. Desde como proponerla  a como culminarla.</li>
              </ol>
            </div>
            <div className="card-footer">
              <a href="https://pay.hotmart.com/K79085615E" target="_blank" className='button-cta' rel="noreferrer">
                <button>Conocer Curso</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="divider">
        <hr />
      </div>
      {/* testimonials */}
      <section>
        <h3 className='text-center mt-16 text-3xl'>TESTIMONIOS</h3>
        <div className="col-start-3 col-end-11 testimonials-section wrapper">
          <div className="grid grid-flow-row-dense sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-3">
            <div href="#" className="flex justify-center col-start-auto col-end-auto block max-w-sm bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 bg-black dark:border-gray-700 dark:hover:bg-gray-700 card-vid">
              <iframe src="https://www.youtube.com/embed/FNMGEVmQBIU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>

            <div href="#" className="flex justify-center col-start-auto col-end-auto block max-w-sm bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 bg-black dark:border-gray-700 dark:hover:bg-gray-700 card-vid">
              <iframe src="https://www.youtube.com/embed/bibJFqMF4AM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>

            <div href="#" className="flex justify-center col-start-auto col-end-auto block max-w-sm bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 bg-black dark:border-gray-700 dark:hover:bg-gray-700 card-vid">
              <iframe width="auto" height="600" src="https://www.youtube.com/embed/LWyp1nTnLOs" title="Testimonios Raúl" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>

            <div href="#" className="flex justify-center col-start-auto col-end-auto block max-w-sm bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 bg-black dark:border-gray-700 dark:hover:bg-gray-700 card-vid">
              <iframe src="https://www.youtube.com/embed/6qWUTA2sSnk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>

            <div href="#" className="flex justify-center col-start-auto col-end-auto block max-w-sm bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 bg-black dark:border-gray-700 dark:hover:bg-gray-700 card-vid">
              <iframe width="auto" height="600" src="https://www.youtube.com/embed/mVmUyUKogKw" title="Testimonios Raúl" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>

            <div href="#" className="flex justify-center col-start-auto col-end-auto block max-w-sm bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 bg-black dark:border-gray-700 dark:hover:bg-gray-700 card-vid">
              <iframe src="https://www.youtube.com/embed/S2uSDFrJ8uc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}
